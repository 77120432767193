<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <h3 class="mb-2">Смена пароля</h3>
      <p>Пароль должен быть минимум 10 символов, включая Заглавные и строчные буквы и спец.символы.</p>
      <VForm v-model="valid" lazy-validation  ref="form" @submit.prevent="onSubmit">
        <VRow>
          <VCol cols="12">
            <div :class="$style.label">Логин:</div>
            <VTextField :value="login" readonly outlined dense hide-details="auto" :rules="rules.required" />
          </VCol>
          <VCol cols="12">
            <div :class="$style.label">Контрольная строка:</div>
            <VTextField :value="word" readonly outlined dense hide-details="auto" :rules="rules.required" />
          </VCol>
          <VCol cols="12">
            <div :class="$style.label">Новый пароль:</div>
            <PasswordField v-model="password" :rules="rules.required" />
          </VCol>
          <VCol cols="12">
            <div :class="$style.label">Подтверждение пароля</div>
            <PasswordField v-model="confirmation" :rules="rules.required" />
          </VCol>
          <VCol cols="12">
            <div :class="$style.label">Введите слово на картинке</div>
            <div class="mb-2">
              <VImg :src="img" width="180" height="40" />
            </div>
            <VTextField v-model="captcha" outlined dense hide-details="auto" :rules="rules.required" />
          </VCol>
          <VCol cols="12">
            <VBtn color="primary" width="100%" large type="submit" depressed :loading="pending">Изменить пароль</VBtn>
          </VCol>
        </VRow>
      </VForm>
    </div>
  </div>
</template>

<script>
import { split, last, get } from 'lodash-es';
import api from '@/store/user/api';
import PasswordField from '@/components/user/Profile/components/PasswordField';

import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'UserPasswordChangeView',
  components: {
    PasswordField,
  },
  data() {
    return {
      img: '',
      valid: true,
      password: '',
      confirmation: '',
      captcha: '',
      rules: {
        required: [(v) => !!v || 'Обязательное поле'],
      },
    };
  },
  computed: {
    ...mapGetters({
      pending: 'user/pending',
    }),
    sid() {
      return last(split(this.img, 'captcha_sid='));
    },
    word() {
      return get(this.$route.query, 'USER_CHECKWORD', '');
    },
    login() {
      return get(this.$route.query, 'USER_LOGIN', '');
    },
  },
  methods: {
    ...mapActions({
      changePasswordUser: 'user/changePasswordUser',
    }),
    getCaptcha: function() {
      return api.changePassword({}).then(({ captchaImg }) => {
        this.img = captchaImg;
      });
    },
    onSubmit: function() {
      if (this.$refs.form.validate()) {
        const word = this.word;
        const login = this.login;
        const password = this.password;
        const captcha = this.captcha;
        const sid = this.sid;
        const confirmation = this.confirmation;
        this.changePasswordUser({
          word,
          login,
          password,
          confirmation,
          captcha,
          sid,
        })
        .then(() => {
          this.$router.push({ name: 'user/ProfileView' })
        })
        .catch(() => {
          this.getCaptcha();
        });
      }
    }
  },
  mounted() {
    this.getCaptcha();
  },
}
</script>

<style module lang="scss">
.root {
  max-width: 400px;
  margin: auto;
}
.inner {
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  font-size: 14px;
}
.label {
  font-weight: 500;
  margin-bottom: 8px;
}
.control {
  text-align: center;
  font-size: 12px;
}
.link {
  color: #f57f29;
  text-decoration: underline;
}
</style>
